<template>
  <div class="layout-container pb-48 md:pb-64">
    <div v-if="model.title || model.linkItem" class="sm:flex justify-between mb-16">
      <h2 v-if="model.title" class="block mb-8 sm:mb-0 text-lg sm:text-xl leading-base sm:leading-lg">
        {{ model.title }}
      </h2>
      <GlobalsLinkHelper
        v-if="model.linkItem"
        :to="model.linkItem.href"
        class="arrow-link self-end text-xs sm:text-sm sm:ml-auto"
      >
        {{ model.linkItem.text }}
      </GlobalsLinkHelper>
    </div>
    <div class="md:grid md:grid-cols-2 md:gap-24">
      <div
        v-if="model.product1"
        class="relative bg-cover bg-center rounded-md"
        :style="`background-image: url(${backgroundImage(model.product1) ? backgroundImage(model.product1) : ''})`"
      >
        <nuxt-img
          v-if="!backgroundImage(model.product1)"
          :src="backgroundGradient" 
          class="top-0 left-0 absolute w-full h-full object-cover object-center rounded-md"
          preset="productImage"
          alt="product gradient"
          sizes="sm:380px md:620px"
        />
        <div
          v-if="!backgroundImage(model.product1)"
          :style="`background-color: ${model.product1.backgroundColor};`"
          class="absolute top-0 left-0 rounded w-full h-full object-cover mix-blend-color"
        />
        <div class="relative z-20 items-center p-24 pt-28 lg:flex lg:p-56 w-full">
          <GlobalsLinkHelper
            v-if="product1 && product1.imageUrl"
            :to="product1.url"
            class="flex-shrink-0 mx-auto lg:mr-48 p-32 lg:p-0 max-w-[212px] max-w-[184px]"
          >
            <nuxt-img
              v-if="product1.imageUrl"
              :src="product1.imageUrl"
              :alt="product1.name"
              loading="lazy"
              preset="productImage"
              sizes="sm:250px md:350px"
              class="aspect-square object-cover mx-auto lg:mx-0 max-w-[184px]"
              height="184"
              width="184"
            />
            <nuxt-img
              v-if="!model.product1.disableCanShadow"
              src="/images/can-shadow-mobile.png"
              class="absolute w-full top-0 left-0 h-auto object-contain block z-20 lg:hidden max-h-[290px] transform -translate-y-6"
              sizes="sm:300px md:500px"
              alt="shadow"
              preset="standard"
            />
            <nuxt-img
              v-if="!model.product1.disableCanShadow"
              src="/images/can-shadow-desktop.png"
              class="absolute top-0 left-0 object-contain block h-full z-20 hidden max-h-[296px] top-1/2 transform -translate-y-1/2 lg:block"
              alt="shadow"
              preset="standard"
              sizes="sm:420px md:295px"
            />
          </GlobalsLinkHelper>
          <div v-if="product1" class="text-white">
            <div class="text-small-medium mb-4 uppercase mb-12">{{ product1.brand.name }}</div>
            <h4 class="text-small-regular">
              {{ product1.name }}
            </h4>
            <WysiwygWrapper
              v-if="product1.description"
              v-epi-edit="'MainBody'"
              :style-config="{ 'wysiwyg wysiwyg--tiny-text': true }"
              :html="product1.description"
            />
            <div v-if="model.activateQuickbuy" class="flex mt-12 relative z-30">
              <SelectorVariants
                v-if="product1.variants"
                class="w-full mr-8"
                :items="product1.variants"
                :type="product1.productType"
                :is-runner="product1.isRunner"
                @change="setVariant1"
              />
              <AnimatedBuyButton
                class="quickBuyBuy btn btn--primary flex-shrink-0 btn--xs h-36 sm:h-40"
                :button-text="productListingResources.buy"
                :product="product1"
                :selected-variant="selectedVariant1"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="model.product2"
        class="relative bg-cover bg-center rounded-md flex items-center mt-32 md:mt-0"
        :style="`background-image: url(${backgroundImage(model.product2) ? backgroundImage(model.product2) : ''})`"
      >
        <nuxt-img
          v-if="!backgroundImage(model.product2)"
          :src="backgroundGradient" 
          class="top-0 left-0 absolute w-full h-full object-cover object-center rounded-md"
          preset="productImage"
          alt="product gradient"
          sizes="sm:380px md:620px"
        />
        <div
          v-if="!backgroundImage(model.product2)"
          :style="`background-color: ${model.product2.backgroundColor};`"
          class="absolute top-0 left-0 rounded w-full h-full object-cover mix-blend-color"
        />
        <div class="relative items-center p-24 pt-28 lg:flex lg:p-56 w-full">
          <GlobalsLinkHelper
            v-if="product2 && product2.imageUrl"
            :to="product2.url"
            class="flex-shrink-0 mx-auto lg:mr-48 p-32 lg:p-0 w-[212px] w-[184px]"
          >
            <nuxt-img
              v-if="product2.imageUrl"
              :src="product2.imageUrl"
              :alt="product2.name"
              loading="lazy"
              preset="productImage"
              sizes="sm:250px md:350px"
              class="aspect-square object-cover mx-auto lg:mx-0 max-w-[184px]"
              height="184"
              width="184"
            />
            <nuxt-img
              v-if="!model.product2.disableCanShadow"
              src="/images/can-shadow-mobile.png"
              class="absolute w-full top-0 left-0 h-auto object-contain block z-20 lg:hidden max-h-[290px] transform -translate-y-6"
              sizes="sm:300px md:500px"
              alt="shadow"
              preset="standard"
            />
            <nuxt-img
              v-if="!model.product2.disableCanShadow"
              src="/images/can-shadow-desktop.png"
              class="absolute top-0 left-0 object-contain block h-full z-20 hidden max-h-[296px] top-1/2 transform -translate-y-1/2 lg:block"
              alt="shadow"
              preset="standard"
              sizes="sm:420px md:295px"
            />
          </GlobalsLinkHelper>
          <div v-if="product2" class="text-white">
            <div class="text-small-medium mb-4 uppercase mb-12">{{ product2.brand.name }}</div>
            <h4 class="text-small-regular">
              {{ product2.name }}
            </h4>
            <WysiwygWrapper
              v-if="product2.description"
              v-epi-edit="'MainBody'"
              :style-config="{ 'wysiwyg wysiwyg--tiny-text': true }"
              :html="product2.description"
            />
            <div v-if="model.activateQuickbuy" class="flex mt-12 relative z-30">
              <SelectorVariants
                v-if="product2.variants"
                class="w-full mr-8"
                :items="product2.variants"
                :type="product2.productType"
                :is-runner="product2.isRunner"
                @change="setVariant2"
              />
              <AnimatedBuyButton
                class="quickBuyBuy btn btn--primary flex-shrink-0 btn--xs h-36 sm:h-40"
                :button-text="productListingResources.buy"
                :product="product2"
                :selected-variant="selectedVariant2"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ProductPromoBlock, ProductPromoItemBlock } from '~/content-types';
import { computed, ref } from 'vue';
import { useUiStore } from '~/store/ui';
import { storeToRefs } from 'pinia';
import { useGlobalContentStore } from '~/store/globalContent';
import SelectorVariants from '~/components/form-elements/SelectorVariants.vue';
import type { IVariant, IProductsResult } from '~/api-types';
import AnimatedBuyButton from '~/components/form-elements/AnimatedBuyButton.vue';
const globalContentStore = useGlobalContentStore();
const productListingResources = globalContentStore.productListingResources;
const { apiGet } = useApiFetch();
const route = useRoute();

const props = defineProps<{
  model: ProductPromoBlock;
}>();

const { isMobile } = storeToRefs(useUiStore());
const marketSettings = useGlobalContentStore().marketSettings;
const product1 = ref();
const product2 = ref();
if (props.model?.product1?.partNo || props.model?.product2?.partNo) {
  await useAsyncData(
    `product-promo${props.model.contentLink.guidValue}`,
    async() => {
      const product1PartNo = props.model?.product1?.partNo ? '&PartNumbers=' + props.model?.product1?.partNo : null;
      const product2PartNo = props.model?.product2?.partNo ? '&PartNumbers=' + props.model?.product2?.partNo : null;
      const query = `products?countryCode=${marketSettings.countryCode}${product1PartNo}${product2PartNo}&language=${globalContentStore.getAcceptLanguage}&url=${route.path}`;
      const res = await apiGet<IProductsResult>(query);

      if (res && res.products[0]) {
        product1.value = res.products[0];
      }
      if (res && res.products[1]) {
        product2.value = res.products[1];
      }

      return res;
    }
  );
}

const selectedVariant1 = ref<IVariant>(product1.value ? product1.value.variants[0] : '');
const selectedVariant2 = ref<IVariant>(product2.value ? product2.value.variants[0] : '');

const setVariant1 = (variant: IVariant) => {
  selectedVariant1.value = variant;
};

const setVariant2 = (variant: IVariant) => {
  selectedVariant2.value = variant;
};

const backgroundGradient = computed(() => {
  if (isMobile.value) {
    return '/images/gradient-promo-bg-mobile.png';
  }
  return '/images/gradient-promo-bg-desktop.png';
});

const backgroundImage = (product: ProductPromoItemBlock) => {
  if (product.mobileImage && isMobile.value) {
    return product.mobileImage.url;
  } else if (product.desktopImage) {
    return product.desktopImage.url;
  }
  return '';
};

</script>
